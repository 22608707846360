import { render, staticRenderFns } from "./Takeaway.vue?vue&type=template&id=f7d63134&scoped=true&"
import script from "./Takeaway.vue?vue&type=script&lang=js&"
export * from "./Takeaway.vue?vue&type=script&lang=js&"
import style0 from "./Takeaway.vue?vue&type=style&index=0&id=f7d63134&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7d63134",
  null
  
)

export default component.exports